import { Component, OnInit, ViewChild } from '@angular/core';
import { ServerService, Contact } from 'src/app/services/server.service';
import { TranslationService } from 'src/app/services/translation.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { CrumbStep } from 'src/app/widgets/crumbs/crumbs.component';
import { ExpandableTableColumn, ExpandableTableComponent } from 'src/app/widgets/expandable-table/expandable-table.component';
import { UserContextService } from 'src/app/services/user-context.service';
import { toDateString, pause, buildContactName, findByProp } from 'src/app/common/tools';
import _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { CustomSnackBarService } from 'src/app/services/custom-snack-bar.service';
import { replaceAll } from './../../common/tools';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
  buzy: boolean = true;

  state: string;

  _account: string = "";
  orders: any[];


  path: CrumbStep[];
  title: string;

  columns: ExpandableTableColumn[];
  subcolumns: ExpandableTableColumn[];

  orderCache: any = {}
  contactCache: any = {}

  rows: any[] = [];
  selection: any[] = [];

  constructor(private server: ServerService, private navigation: NavigationService, private translation: TranslationService,
    public userContext: UserContextService, public route: ActivatedRoute, private snackBar: CustomSnackBarService) {
    let menu = order => this.getMenuForOrder(order)

    this.columns = [
      { name: "Ordrenummer", fieldName: "orderNumber", type: "text", expander: true, menu: undefined, width: 100, mobile: false, mwidth: '' },
      { name: "Ordredato", fieldName: "createdOnStr", type: "date", expander: false, menu: undefined, width: 95, mobile: false, mwidth: '' },
      { name: "Levering", fieldName: "deliveryDateStr", type: "date", expander: false, menu: undefined, width: 95, mobile: false, mwidth: '' },
      { name: "Bestilt av", fieldName: "orderedBy", type: "text", expander: false, menu: undefined, width: 130, mobile: false, mwidth: '' },
      { name: "Din referanse", fieldName: "customerReference", type: "text", expander: false, menu: undefined, width: 190, mobile: false, mwidth: '' },
      { name: "Vilkår", fieldName: "deliveryTerms", type: "text", expander: false, menu: undefined, width: 100, mobile: false, mwidth: '' },
      { name: "Status", fieldName: "documentStatusAx", type: "text", expander: false, menu: undefined, width: 90, mobile: false, mwidth: '' },
      { name: "Mer", fieldName: undefined, type: "menu", expander: false, menu: menu, width: 60, mobile: false, mwidth: '' }
    ]
    this.subcolumns = [
      { name: "Produkt", fieldName: "productNumberAX", type: "text", expander: true, menu: undefined, width: 120, mwidth: '25%', mobile: true },
      { name: "Beskrivelse", fieldName: "name", type: "text", expander: false, menu: undefined, width: 310, mwidth: '53%', mobile: true },
      { name: "Antall", fieldName: "number", type: "number", expander: false, menu: undefined, width: 90, mwidth: '22%', mobile: true },
      { name: "Enhet", fieldName: "unit", type: "text", expander: false, menu: undefined, width: 100, mwidth: '', mobile: false },
      { name: "Pris per enhet", fieldName: "pricePerUnit", type: "number", expander: false, menu: undefined, width: 120, mwidth: '22%', mobile: false },
      { name: "Beløp", fieldName: "extendedAmount", type: "number", expander: false, menu: undefined, width: 120, mwidth: '22%', mobile: false },
    ]
  }

  get account() {
    return this._account
  }

  set account(value) {
    this._account = value
    this.userContext.setAccount(value)
    this.loadOrdersForAccount()
  }

  getMenuForOrder(order) {
    let self = this
    let menuItems = []
    if (this.state === "open-orders") {
      //menuItems.push({ text: "Endre ordre", action: () => self.editOrder(order) })
      menuItems.push({ text: "Se ordredetaljer", action: () => self.showOrderConfirmation(order) })
    } else {
      menuItems.push({ text: "Se ordredetaljer", action: () => self.showOrderView(order) })
    }


    menuItems.push({ text: "Kopier ordre", action: () => this.copyOrder(order) })

    if (this.state === "history" && isSelectable(order)) {
      menuItems.push({ text: "Bestill faktura", action: () => this.requestInvoiceForOrder(order) })
    }

    return menuItems
  }

  copyOrder(order) {
    this.navigation.goToUrl("/copy/" + order.orderId, undefined)
  }

  tr(text: string): string {
    return this.translation.translate(text)
  }

  ngOnInit() {

    this.state = this.route.snapshot.data.mode
    var url
    if (this.state === "open-orders") {
      url = "/orders"
      this.title = "Åpne ordrer"
      this.path = [
        { text: "Ordre", target: "" },
        { text: "Åpne ordrer", target: "" }
      ]

      if (this.navigation.checkLogin(url, this.title)) {
        this.loadOrders()
      }
    } else if (this.state === "history") {
      url = "/history"
      this.title = "Ordrehistorikk"
      this.path = [
        { text: "Ordre", target: "" },
        { text: "Ordrehistorikk", target: "" }
      ]


      this.columns[3].width = 130
      this.columns[4].width = 170

      if (this.navigation.checkLogin(url, this.title)) {
        this.loadOrders()
      }
    } else {
      console.error("Bad state", this.state)
    }
  }

  async loadOrders() {
    await this.userContext.fetchAccounts()
    this._account = this.userContext.getAccount()

    await this.loadOrdersForAccount()

    this.buzy = false
  }

  async loadOrdersForAccount() {

    const documentStatus = {
      778380000: "Registered",
      778380001: "Confirmation",
      778380002: "Invoice"
    }

    this.orders = []
    let ordersResult = await this.server.getOrders(this.account)
    console.log(ordersResult.data)
    let filtered = ordersResult.data.filter(order => { return this.shouldShow(order) })
    filtered.forEach(order => {
      order.createdOnStr = toDateString(new Date(order.createdOn))
      order.deliveryTerms = order.deliveryTermsText
      let date
      if (order.confirmedDeliveryDate) {
        date = order.confirmedDeliveryDate
      } else {
        date = order.deliveryDate
      }
      order.deliveryDateStr = toDateString(new Date(date))
      order.lines = []
      order.salesStatusAx = order.salesStatusAx //replaceAll(order.salesStatusAx, "_", " ")
      order.documentStatusAx = documentStatus[order.documentStatusAx] //replaceAll(order.documentStatusAx, "_", " ")
    })
    this.orders = _.orderBy(filtered, "createdOn", "desc")
    this.fetchContactNames()
  }

  shouldShow(order) {
    let status = order.salesStatusAx || ""
    console.log(status)
    if (this.state === "open-orders") {
      return status === "" || status === 778380000
    } else {
      return status === 778380001 || status === 778380002
    }
  }

  async fetchContactNames() {
    for (let order of this.orders) {
      await this.fetchContactName(order)
    }
  }

  async fetchContactName(order) {
    let contactId = order.contact
    let contact = this.contactCache[contactId]
    if (!contact) {
      try {
        let contactResponse = await this.server.getContact(contactId)
        if (!contactResponse.data) {
          return
        }
        contact = contactResponse.data
        this.contactCache[contactId] = contact
      } catch (e) {

      }
    }

    order.orderedBy = buildContactName(contact)
  }

  showOrderConfirmation(item) {
    this.navigation.router.navigate(["/orders/" + item.orderId])
  }

  showOrderView(item) {
    this.navigation.router.navigate(["/history/" + item.orderId])
  }

  editOrder(item) {
    this.navigation.router.navigate(["/orders/" + item.orderId + "/edit"])
  }

  getFilteredOrders() {
    return this.orders
  }

  requestDisabled() {
    return this.state !== "history" || this.selection.length === 0
  }

  onSelectionChanged(selection) {
    this.selection = selection
  }

  requestInvoice() {
    this.requestInvoiceCore(this.selection)
  }

  getOrderNumber(orderId) {
    let order = findByProp(this.orders, "orderId", orderId)
    return order.orderNumberAx
  }

  getAccountObject() {
    let accounts = this.userContext.getAccounts()
    let account = findByProp(accounts, "id", this.account)
    return account
  }

  getAccountName() {
    let account = this.getAccountObject()
    return account.name
  }

  async requestInvoiceCore(orderIds) {
    if (this.state !== "sending") {
      let oldState = this.state
      let name = this.getAccountName()
      let subject = "Bestill faktura for " + name
      let body = "Kunde har bestilt fakturaer via portalen.\n" +
        "Kunde: " + this.account + " " + name + "\n" +
        "Ordrer:\n"
      let orderNumbers = orderIds.map(id => { return this.getOrderNumber(id) })
      body += orderNumbers.join("\n")
      body = body.substring(0, 2000)

      this.state = "sending"
      let result = await this.server.sendEmail(this.account, "Invoice", subject, body)
      if (result.error) {
        this.state = oldState
        if (result.error.status === 403) {
          this.snackBar.notify("Beklager, dine brukerrettigheter tillater ikke å bestille disse dokumentene");
        } else {
          this.snackBar.notify("Det oppsto en feil");
        }
      } else {
        this.state = "sent"
        this.snackBar.notify("Faktura har blitt bestilt");
        setTimeout(() => { this.state = oldState }, 3000)
      }
    }
  }

  async requestInvoiceForOrder(item) {
    let ids = [item.orderId]
    this.requestInvoiceCore(ids)
  }

  getRequestButtonText() {
    if (this.state === "ready") {
      return this.tr("Bestill faktura for utvalgte ordrer")
    } else {
      return this.tr("Bestiller...")
    }
  }

  async onRowExpanded(row) {

    let order = this.orderCache[row.orderId]
    if (!order) {
      let orderData = await this.server.getOrder(row.orderId)
      order = orderData.data
      this.orderCache[row.orderId] = order
    }
    let account = this.getAccountObject()
    row.lines = order.lines.map(line => { return convertOrderLine(account, line) })
  }

  goToCreateOrder() {
    this.navigation.goToUrl("/create-order", undefined)
  }
}

function convertOrderLine(account, line) {
  return {
    id: line.productId,
    number: line.quantity,
    productNumberAX: line.product.productNumberAX,
    name: line.product.name,
    unit: line.product.unitOfMeasurement.name,
    pricePerUnit: formatAmount(line.pricePerUnit, account),
    extendedAmount: formatAmount(line.extendedAmount, account)
  }
}

function formatAmount(amount, account) {
  if (amount && account.currency && account.currency.isoCode) {
    return amount + " " + account.currency.isoCode
  }

  return ""
}


function isSelectable(order) {
  return true
}

