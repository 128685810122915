import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { CrumbStep } from 'src/app/widgets/crumbs/crumbs.component';
import { UserContextService } from 'src/app/services/user-context.service';
import { pause } from 'src/app/common/tools';
import { ServerService } from 'src/app/services/server.service';
import { CustomSnackBarService } from 'src/app/services/custom-snack-bar.service';

@Component({
  selector: 'app-customer-service',
  templateUrl: './customer-service.component.html',
  styleUrls: ['./customer-service.component.css']
})
export class CustomerServiceComponent implements OnInit {

  path: CrumbStep[] = [
    { text: "Kontakt", target: "" },
    { text: "Kundeservice", target: "" }
  ];

  legalEntity: string = "";
  _account: string;
  buzy: boolean = true;
  message: string = "";
  sendDisabled: boolean = false;
  errorMessage: string = "";
  messageShown: boolean = true;

  constructor(public userContext: UserContextService, private translation: TranslationService,
    private navigation: NavigationService, private server: ServerService, private snackBar: CustomSnackBarService
  ) { }

  tr(text: string): string {
    return this.translation.translate(text)
  }

  ngOnInit(): void {
    if (this.navigation.checkLogin("/customer-service", "Kundeservice")) {
      this.loadAccount()
    }
  }

  async loadAccount() {
    await this.userContext.fetchAccounts()


    this.account = this.userContext.getAccount()

    this.buzy = false
  }

  get account() {
    return this._account
  }

  set account(value) {
    this._account = value
    this.userContext.setAccount(value)
    this.legalEntity = this.userContext.getAccountObject().legalEntity || "Norway"
  }

  nb() {
    return this.translation.language === "nb"
  }

  en() {
    return this.translation.language === "en"
  }

  da() {
    return this.translation.language === "da"
  }

  async contactMe() {
    let message = this.message.trim()
    if (!message) {
      this.errorMessage = this.tr("Skriv inn en beskjed")
      return
    }
    this.errorMessage = ""
    this.sendDisabled = true
    let result = await this.server.sendEmail(this.account, "ContactMe", "Contact me", message)
    if (result.error) {
      this.snackBar.notify("Det oppsto en feil")
      await pause(1000)
      this.sendDisabled = false
    } else {
      this.messageShown = false
    }
  }
}
