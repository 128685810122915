import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  details: string;

  constructor(private router: Router, private translation: TranslationService, public route: ActivatedRoute) { }

  tr(text: string): string {
    return this.translation.translate(text)
  }

  ngOnInit() {
    let message = this.route.snapshot.data.message
    if (message) {
      this.details = this.tr(message)
    } else {
      this.details = ""
    }
  }

  goHome() {
    this.router.navigate(["/"])
  }

  getDetails() {
    if (this.details) {
      return this.details
    }

    return this.tr("Det oppsto en feil")
  }

}
