import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { TranslationService } from './../../services/translation.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  constructor(public login: LoginService, private translation: TranslationService) { }

  tr(text: string): string {
    return this.translation.translate(text)
  }

  goHome() {
    location.href = "/"
  }

  ngOnInit() {
  }

}
