import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { findByProp } from 'src/app/common/tools';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-address-picker',
  templateUrl: './address-picker.component.html',
  styleUrls: ['./address-picker.component.css']
})

export class AddressPickerComponent implements OnInit {

  @Input()
  editable: boolean = true;
  @Input()
  addresses: any[];
  @Input()
  address: any = {};

  @Output()
  onAddressChanged = new EventEmitter<any>()


  state: string = "normal";

  customAddress: any = {
    line1: "",
    line2: "",
    line3: "",
    city: "",
    postalCode: "",
    country: ""
  };
  customChosen: boolean = false;
  error: string;


  constructor(private translation: TranslationService) { }

  tr(text: string): string {
    return this.translation.translate(text)
  }

  ngOnInit() {
  }

  showAddressesChoices() {
    this.state = "choosing"
  }

  chooseAddress(id) {
    this.deselectAllAddresses()
    this.address = findByProp(this.addresses, "addressId", id)
    this.address.chosen = true
    this.state = "normal"
    this.onAddressChanged.emit(this.address)
  }

  chooseCustom() {
    if (!this.customAddress.line1 || !this.customAddress.name || !this.customAddress.postalCode || !this.customAddress.city) {
      this.error = this.tr("Fyll ut adressen")
    } else {
      this.error = ""
      this.deselectAllAddresses()
      this.address = this.customAddress
      this.address.chosen = true
      this.state = "normal"
      this.onAddressChanged.emit(this.address)
    }
  }

  deselectAllAddresses() {
    for (let other of this.addresses) {
      other.chosen = false
    }
    this.customAddress.chosen = false
  }
}
