import { Component, OnInit } from '@angular/core';
import { CrumbStep } from 'src/app/widgets/crumbs/crumbs.component';
import { TranslationService } from 'src/app/services/translation.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UserContextService } from 'src/app/services/user-context.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  path: CrumbStep[] = [
    { text: "Vilkår", target: "" },
    { text: "Kjøpsbetingelser", target: "" }
  ];



  legalEntity: string = "";
  _account: string;
  buzy: boolean = true

  constructor(public userContext: UserContextService, private translation: TranslationService, private navigation: NavigationService,
  ) { }

  tr(text: string): string {
    return this.translation.translate(text)
  }

  ngOnInit(): void {
    if (this.navigation.checkLogin("/terms", "Kjøpsbetingelser")) {
      this.loadAccount()
    }
  }

  async loadAccount() {
    await this.userContext.fetchAccounts()


    this.account = this.userContext.getAccount()

    this.buzy = false
  }

  get account() {
    return this._account
  }

  set account(value) {
    this._account = value
    this.userContext.setAccount(value)
    this.legalEntity = this.userContext.getAccountObject().legalEntity || "Norway"
  }
}
