import { Component, OnInit, ViewChildren, QueryList, ElementRef, Input } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';
import { get, findByProp } from 'src/app/common/tools';
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ProductLookupComponent } from './../product-lookup/product-lookup.component';

const maxQty = 10000


@Component({
  selector: 'app-order-lines',
  templateUrl: './order-lines.component.html',
  styleUrls: ['./order-lines.component.css']
})
export class OrderLinesComponent implements OnInit {

  @Input()
  set order(obj) {
    this._order = obj;
    this.showQuantities()
  }

  get order() {
    return this._order
  }

  _order: any;

  @Input()
  editable: boolean = false;

  @Input()
  currency: string = "";

  @ViewChildren("quantityInput")
  qtyInputs: QueryList<ElementRef>;

  @Input()
  products: any[] = [];

  @Input()
  lastProducts: any[];

  category; string;


  constructor(private translation: TranslationService, private dialog: MatDialog) { }

  tr(text: string): string {
    return this.translation.translate(text)
  }

  ngOnInit() {
  }



  addLine() {
    let categories = this.getProductCategories()
    let category = this.getCategory()

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      products: this.products,
      categories: categories,
      category: category,
      lastProducts: this.lastProducts,
      lines: this.order.lines
    }

    let dialogRef = this.dialog.open(ProductLookupComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(

      data => this.addLineCore(data)
    );
  }

  getProductCategories() {
    let categoriesSet = {}
    for (let product of this.products) {
      if (product.category) {
        categoriesSet[product.category] = true
      }
    }
    let categories = Object.keys(categoriesSet)
    categories.sort()
    return categories
  }

  getCategory() {
    return this.category
  }


  addLineCore(data) {
    if (data.productId) {
      let product = findByProp(this.products, "id", data.productId)
      product.extProductNo = data.extProductNo
      this.category = data.category
      var unit = ""
      if (product.unitOfMeasurement) {
        unit = product.unitOfMeasurement.name
      }

      this.order.lines.push({
        id: data.productId,
        extProductNo: data.extProductNo,
        productNumberAX: product.productNumberAX,
        name: product.name,
        number: 1,
        unit: unit
      })
      this.showQuantities()
    }

  }

  canEdit(line) {
    return this.editable
  }

  deleteLine(lineId) {
    this.order.lines.splice(lineId, 1)
  }

  removeOne(lineId) {
    let line = this.order.lines[lineId]
    line.number--
    line.number = Math.max(1, line.number)
    this.refillQtyInputs()
  }

  addOne(lineId) {
    let line = this.order.lines[lineId]
    line.number++
    line.number = Math.min(maxQty, line.number)
    this.refillQtyInputs()
  }

  updateQuantity(event, lineOrdinal) {
    let quantity = parseInt(event.target.value)
    if (!isNaN(quantity) && quantity > 0 && quantity <= maxQty) {
      this.order.lines[lineOrdinal].number = quantity
    }
  }

  showQuantities() {
    setTimeout(() => { this.refillQtyInputs() }, 0)
  }

  refillQtyInputs() {
    if (!this.order) {
      return
    }
    for (let i = 0; i < this.order.lines.length; i++) {
      let id = "qty_" + i
      let idm = "qtym_" + i
      let line = this.order.lines[i]
      setInputValue(id, line.number)
      setInputValue(idm, line.number)
    }
  }



}

function setInputValue(id, value) {
  let input = <HTMLInputElement>get(id)
  if (input) {
    input.value = value
  }
}
