import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServerService, Contact, Address } from './../../services/server.service';
import { inputHasData, checkPasswordStrength, trimValue } from 'src/app/common/tools';
import * as _ from 'lodash';
import { TranslationService } from 'src/app/services/translation.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { CrumbStep } from 'src/app/widgets/crumbs/crumbs.component';
import { CustomSnackBarService } from 'src/app/services/custom-snack-bar.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  path: CrumbStep[] = [
    { text: "Min profil", target: "" },
    { text: "Innstillinger", target: "" }
  ];
  buzy: boolean = true;
  originalContact: Contact = createEmptyContact();
  contactError: string = "";
  changePasswordError: string = "";
  changePasswordVisible: boolean = false;

  profileForm = new FormGroup({
    firstName: new FormControl("", Validators.required),
    lastName: new FormControl("", Validators.required),
    emailAddress: new FormControl(""),
    mobilePhone: new FormControl("", Validators.required),
    businessPhone: new FormControl(""),
    jobTitle: new FormControl(""),
    addressLine1: new FormControl(""),
    addressLine2: new FormControl(""),
    addressLine3: new FormControl(""),
    addressCity: new FormControl(""),
    addressStateOrProvince: new FormControl(""),
    addressPostalCode: new FormControl(""),
    addressCountry: new FormControl("")
  })

  changePasswordForm = new FormGroup({
    oldPassword: new FormControl("", Validators.required),
    newPassword1: new FormControl("", Validators.required),
    newPassword2: new FormControl("", Validators.required)
  })


  constructor(private server: ServerService, private snackBar: CustomSnackBarService, private navigation: NavigationService, private translation: TranslationService) { }

  tr(text: string): string {
    return this.translation.translate(text)
  }

  ngOnInit() {
    if (this.navigation.checkLogin("/profile", "Profil")) {
      this.loadContact()
    }
  }

  async loadContact() {
    this.originalContact = await this.server.fetchContactInformation()
    this.profileForm.controls.firstName.setValue(this.originalContact.firstName)
    this.profileForm.controls.lastName.setValue(this.originalContact.lastName)
    this.profileForm.controls.emailAddress.setValue(this.originalContact.emailAddress)
    this.profileForm.controls.mobilePhone.setValue(this.originalContact.mobilePhone)
    this.profileForm.controls.businessPhone.setValue(this.originalContact.businessPhone)
    this.profileForm.controls.jobTitle.setValue(this.originalContact.jobTitle)
    this.profileForm.controls.addressLine1.setValue(this.originalContact.address.line1)
    this.profileForm.controls.addressLine2.setValue(this.originalContact.address.line2)
    this.profileForm.controls.addressLine3.setValue(this.originalContact.address.line3)
    this.profileForm.controls.addressCity.setValue(this.originalContact.address.city)
    this.profileForm.controls.addressStateOrProvince.setValue(this.originalContact.address.stateOrProvince)
    this.profileForm.controls.addressPostalCode.setValue(this.originalContact.address.postalCode)
    this.profileForm.controls.addressCountry.setValue(this.originalContact.address.country)
    this.buzy = false
  }

  async saveContactInformation() {
    this.contactError = ""

    if (!inputHasData(this.profileForm.controls.firstName)) {
      this.contactError = this.tr("Vennligst tast inn fornavn")
      return
    } else if (!inputHasData(this.profileForm.controls.lastName)) {
      this.contactError = this.tr("Vennligst tast inn etternavn")
      return
    } else if (!inputHasData(this.profileForm.controls.mobilePhone)) {
      this.contactError = this.tr("Vennligst tast inn telefonnummer")
      return
    }

    let contact = this.extractContact()

    this.buzy = true
    try {
      await this.server.saveContactInformation(contact)
      this.originalContact = _.clone(contact)
      this.notify("Lagret kontaktinformasjon")
    } catch (e) {
      console.error(e)
      this.notify("Det oppsto en feil")
    }
    this.buzy = false
  }

  extractContact(): Contact {
    let address: Address = {
      line1: trimValue(this.profileForm.controls.addressLine1),
      line2: trimValue(this.profileForm.controls.addressLine2),
      line3: trimValue(this.profileForm.controls.addressLine3),
      city: trimValue(this.profileForm.controls.addressCity),
      stateOrProvince: trimValue(this.profileForm.controls.addressStateOrProvince),
      postalCode: trimValue(this.profileForm.controls.addressPostalCode),
      country: trimValue(this.profileForm.controls.addressCountry)
    }

    let contact = {
      parentAccount: "",
      contactNumber: this.originalContact.contactNumber,
      firstName: trimValue(this.profileForm.controls.firstName),
      lastName: trimValue(this.profileForm.controls.lastName),
      emailAddress: undefined,
      mobilePhone: trimValue(this.profileForm.controls.mobilePhone),
      jobTitle: trimValue(this.profileForm.controls.jobTitle),
      preferredLanguage: undefined,
      businessPhone: trimValue(this.profileForm.controls.businessPhone),
      address: address
    }

    return contact
  }

  logout() {
    this.navigation.loginService.logout()
    window.location.href = "/login"
  }

  async changePassword() {
    let oldPassword = this.changePasswordForm.controls.oldPassword.value
    let new1 = this.changePasswordForm.controls.newPassword1.value
    let new2 = this.changePasswordForm.controls.newPassword2.value

    if (oldPassword === "") {
      this.changePasswordError = this.tr("Tast inn nåværende passord")
      return
    }

    if (new1 === "") {
      this.changePasswordError = this.tr("Tast inn nytt passord")
      return
    }

    if (new1 !== new2) {
      this.changePasswordError = this.tr("De nye passordene er ikke like")
      return
    }

    let message = checkPasswordStrength(new1)
    if (message) {
      this.changePasswordError = this.tr(message)
      return
    }

    this.changePasswordForm.controls.oldPassword.setValue("")
    this.changePasswordForm.controls.newPassword1.setValue("")
    this.changePasswordForm.controls.newPassword2.setValue("")

    this.buzy = true
    this.changePasswordVisible = false
    try {
      let result = await this.server.changePassword({ newPassword: new1, oldPassword: oldPassword })
      if (result.error) {
        this.changePasswordVisible = true
        this.notify("Klarte ikke endre passordet")
      } else {
        this.notify("Passordet har blitt endret")
      }
    } catch (e) {
      console.error(e)
      this.notify("Det oppsto en feil")
    }
    this.buzy = false
  }

  onSubmit() {

  }

  contactHasChanged(): boolean {
    if (!this.originalContact) {
      return false
    }

    return true
  }

  notify(message: string) {
    this.snackBar.notify(message)
  }
}

function createEmptyContact(): Contact {
  let address: Address = {
    line1: "",
    line2: "",
    line3: "",
    city: "",
    stateOrProvince: "",
    postalCode: "",
    country: ""
  }

  let contact = {
    parentAccount: "",
    contactNumber: "",
    firstName: "",
    lastName: "",
    emailAddress: undefined,
    mobilePhone: "",
    jobTitle: "",
    preferredLanguage: undefined,
    businessPhone: "",
    address: address
  }

  return contact
}

