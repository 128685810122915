import { MatSnackBar } from '@angular/material/snack-bar';

import { Injectable } from '@angular/core';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})

export class CustomSnackBarService {

  constructor(private snackBar: MatSnackBar, private translation: TranslationService) { }

  notify(message: string) {
    this.snackBar.open(this.translation.translate(message), "", {
      duration: 4000,
      verticalPosition: "top"
    });
  }
}
