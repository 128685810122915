import { Component, OnInit, Input } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-crumbs',
  templateUrl: './crumbs.component.html',
  styleUrls: ['./crumbs.component.css']
})
export class CrumbsComponent implements OnInit {
  @Input()
  path: CrumbStep[];

  constructor(private translation: TranslationService) { }

  tr(text: string): string {
    return this.translation.translate(text)
  }

  ngOnInit() {
  }


}

export class CrumbStep {
  text: string;
  target: string;
}
