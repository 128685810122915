import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { TranslationService } from 'src/app/services/translation.service';
import { ServerService } from 'src/app/services/server.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { checkPasswordStrength } from 'src/app/common/tools';
import { CustomSnackBarService } from 'src/app/services/custom-snack-bar.service';


@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent implements OnInit {
  token: string = "";
  state: string = "ready";
  error: string = "";
  setForm = new FormGroup({
    newPassword1: new FormControl("", Validators.required),
    newPassword2: new FormControl("", Validators.required)
  })


  constructor(private navigation: NavigationService, private translation: TranslationService,
    private server: ServerService, public route: ActivatedRoute, private snackBar: CustomSnackBarService, ) { }

  tr(text: string): string {
    return this.translation.translate(text)
  }

  ngOnInit() {
    this.navigation.setTitle("Lag et passord")
    this.token = this.getParameter("token")
  }

  getParameter(id: string): string {
    return this.route.snapshot.params[id]
  }

  setPassword() {

  }

  async changePassword() {
    if (this.state != "ready") {
      return
    }

    this.error = ""

    let new1 = this.setForm.controls.newPassword1.value
    let new2 = this.setForm.controls.newPassword2.value

    if (new1 === "") {
      this.error = this.tr("Tast inn nytt passord")
      return
    }

    if (new1 !== new2) {
      this.error = this.tr("De nye passordene er ikke like")
      return
    }

    let message = checkPasswordStrength(new1)

    if (message) {
      this.error = this.tr(message)
      return
    }

    this.state = "sending"
    try {
      await this.server.setPassword({ token: this.token, newPassword: new1 })
      this.setForm.controls.newPassword1.setValue("")
      this.setForm.controls.newPassword2.setValue("")
      this.notify("Passordet har blitt endret")
      this.state = "success"
    } catch (e) {
      console.error(e)
      this.notify("Det oppsto en feil")
      this.state = "ready"
    }
  }

  notify(message: string) {
    this.snackBar.notify(message)
  }

  goToLogin() {
    this.navigation.router.navigate(["/login"])
  }
}
