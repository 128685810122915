import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { sendPost, sendGet, sendPatch, normalizeLanguage, sendPut, findByProp, pause, sendPostRaw } from '../common/tools';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ServerService {
  baseUrl: string;

  orders: any[] = makeFakeOrders();

  constructor(private http: HttpClient) {
    this.baseUrl = environment.api + "/api"
  }

  async fetchContactInformation(): Promise<Contact> {
    let response = await this.get("/Contacts/me")
    let contact = <Contact>response.data

    contact.preferredLanguage = normalizeLanguage(contact.preferredLanguage)
    return contact
  }

  async get(url: string) {
    let headers = this.buildHeaders()
    let response = await sendGet(this.http, this.baseUrl + url, headers)
    return response
  }

  async post(url: string, body: any) {
    let headers = this.buildHeaders()
    let response = await sendPost(this.http, this.baseUrl + url, body, headers)
    return response
  }

  async patch(url: string, body: any) {
    let headers = this.buildHeaders()
    let response = await sendPatch(this.http, this.baseUrl + url, body, headers)
    return response
  }

  buildHeaders() {
    let token = localStorage.getItem("access_token")
    return {
      "Authorization": "Bearer " + token
    }
  }

  async saveContactInformation(contactInfo: Contact): Promise<void> {
    await this.patch("/Contacts/me", contactInfo)
  }

  async changePassword(change: PasswordChange) {
    let payload = {
      currentPassword: change.oldPassword,
      newPassword: change.newPassword
    }

    return await this.patch("/Identities/passwords", payload)
  }

  async resetPassword(email: string): Promise<boolean> {
    let payload = {
      userPrincipalName: email
    }
    let result = await this.post("/Identities/passwords", payload)
    return !result.error
  }

  async setPassword(passwordData: SetPassword): Promise<boolean> {
    let body = {
      newPassword: passwordData.newPassword
    }
    let headers = {
      "X-Verify-Token": passwordData.token
    }
    let response = await sendPut(this.http, this.baseUrl + "/Identities/passwords", body, headers)
    return !response.error
  }

  async getOrder(orderId: string) {
    let url = '/Orders/' + orderId
    let result = await this.get(url)
    if (result.data) {
      result.data.orderNumber = normalizeOrderNumber(result.data.orderNumber)
    }
    return result
  }

  async getAccountDocuments(accountId) {
    let url = `/Accounts/${accountId}/attachments`
    return await this.get(url)
  }

  async downloadAccountDocument(attachmentId) {
    let url = `/Accounts/attachments/${attachmentId}`
    return await this.get(url)
  }

  async getArticles(productNumber) {
    let url = `/Products/${productNumber}/articles`
    return await this.get(url)
  }

  async downloadDocument(attachmentId) {
    let url = `/Products/articles/attachments/${attachmentId}`
    return await this.get(url)
  }

  async getDeliveryOptions() {
    return await this.get("/Orders/deliveryoptions")
  }

  async getAccounts() {
    return await this.get("/Accounts")
  }

  async getAddresses(accountId) {
    return await this.get("/Accounts/" + accountId + "/addresses")
  }

  async getProducts(country) {
    return await this.get("/Products?country=" + country)
  }

  async sendOrder(order) {
    let url = "/Orders"
    let headers = this.buildHeaders()
    let response = await sendPostRaw(this.http, this.baseUrl + url, order, headers)
    return response
  }

  async confirmSuggestedOrder(orderId) {
    let url = "/Orders/" + orderId + "/confirm"
    let body = {
      confirm: true
    }
    return await this.post(url, body)
  }

  async rejectSuggestedOrder(orderId) {
    let url = "/Orders/" + orderId + "/confirm"
    let body = {
      confirm: false
    }
    return await this.post(url, body)
  }

  async getAccountProducts(accountId) {
    return await this.get("/Accounts/" + accountId + "/products")
  }

  async getOrders(accountId) {
    let result = await this.get("/Accounts/" + accountId + "/orders")
    if (result.data) {
      for (let order of result.data) {
        order.orderNumber = normalizeOrderNumber(order.orderNumber)
      }
    }
    return result
  }

  async getContact(contactId) {
    return await this.get("/Contacts/" + contactId)
  }

  async sendEmail(accountId, type, subject, body) {
    let payload = { type, subject, body }
    let url = "/Accounts/" + accountId + "/requests"
    let headers = this.buildHeaders()
    let response = await sendPostRaw(this.http, this.baseUrl + url, payload, headers)
    return response
  }
}

export class PasswordChange {
  oldPassword: string;
  newPassword: string;
}

export class Contact {
  contactNumber: string;
  emailAddress: string;
  businessPhone: string;
  jobTitle: string;
  preferredLanguage: string;
  address: Address;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  parentAccount: string;
}

export class Address {
  line1: string;
  line2: string;
  line3: string;
  postalCode: string;
  city: string;
  stateOrProvince: string;
  country: string;
}

export class SetPassword {
  token: string;
  newPassword: string;
}

export class DeliveryOptions {
  deliveryTerms: string[];
  deliverySites: any;
}

export function makeFakeOrders() {
  let raw = [
    ["SO 4100-003515", "26.04.19", "Sanniva L.", "DLG 122", "FCA Oslo", "Planlagt", [
      ["99876", "10296", "Salttabletter Axal - 40 x 25 kg", 1, "Paller", "4161,00", "25%", "4161,00", "NOK", true],
      ["99000", "10872", "Miljøavgift - sekk", 40, "Stk", "0,08", "25%", "3,20", "NOK", false],
      ["80000", "10300", "Europalle", 1, "Stk", "97,00", "25%", "97,00", "NOK", false],
      ["10500", "10890", "Transport", 1, "Stk", "1200,00", "25%", "1200,00", "NOK", false]
    ], "asdfasdfasdf"],
    ["SO 4100-014578", "24.04.19", "Tore P.", "DLG 120", "FCA Oslo", "Planlagt", [
      ["99876", "10296", "Salttabletter Axal - 40 x 4 kg", 5, "Paller", "2243,00", "25%", "2243,00", "NOK", true],
      ["99877", "10297", "Salttabletter Axal - 40 x 25 kg", 1, "Paller", "4161,00", "25%", "4161,00", "NOK", true],
      ["99000", "10872", "Miljøavgift - sekk", 40, "Stk", "0,08", "25%", "3,20", "NOK", false],
      ["80000", "10300", "Europalle", 1, "Stk", "97,00", "25%", "97,00", "NOK", false],
      ["10500", "10890", "Transport", 1, "Stk", "1200,00", "25%", "1200,00", "NOK", false]
    ], "kjlksdfasdff"],
    ["SO 4100-009889", "19.04.19", "Tore P.", "DLG 246", "FCA Oslo", "Kansellert", [
      ["99876", "10296", "Salttabletter Axal - 40 x 25 kg", 1, "Paller", "4161,00", "25%", "4161,00", "NOK", true],
      ["99000", "10872", "Miljøavgift - sekk", 40, "Stk", "0,08", "25%", "3,20", "NOK", false],
      ["80000", "10300", "Europalle", 1, "Stk", "97,00", "25%", "97,00", "NOK", false],
      ["10500", "10890", "Transport", 1, "Stk", "1200,00", "25%", "1200,00", "NOK", false]
    ], "542345kj2o345"],
    ["SO 4100-003254", "10.04.19", "Lise M.", "DLG 155", "FCA Oslo", "Levert", [
      ["99876", "10296", "Salttabletter Axal - 40 x 25 kg", 1, "Paller", "4161,00", "25%", "4161,00", "NOK", true],
      ["99000", "10872", "Miljøavgift - sekk", 40, "Stk", "0,08", "25%", "3,20", "NOK", false],
      ["80000", "10300", "Europalle", 1, "Stk", "97,00", "25%", "97,00", "NOK", false],
      ["10500", "10890", "Transport", 1, "Stk", "1200,00", "25%", "1200,00", "NOK", false]
    ], "8234509827903"],
    ["SO 4100-003515", "05.04.19", "Sanniva L.", "DLG 1150", "FCA Oslo", "Levert", [
      ["99876", "10296", "Salttabletter Axal - 40 x 25 kg", 1, "Paller", "4161,00", "25%", "4161,00", "NOK", false],
      ["99000", "10872", "Miljøavgift - sekk", 40, "Stk", "0,08", "25%", "3,20", "NOK", false],
      ["80000", "10300", "Europalle", 1, "Stk", "97,00", "25%", "97,00", "NOK", false],
      ["10500", "10890", "Transport", 1, "Stk", "1200,00", "25%", "1200,00", "NOK", false]
    ], "23452345dfggg"]
  ]

  return raw.map(fromFakeRow)
}

function fromFakeRow(row) {
  return {
    orderNo: row[3],
    orderDate: row[1],
    orderedBy: row[2],
    extOrderNo: row[0],
    incoterm: row[4],
    status: row[5],
    lines: row[6].map(fromFakeSubrow),
    orderId: row[7],
    expanded: false,
    deliveryMethod: "Hentes",
    deliveryDate: "29.04.2019",
    department: "Oslo",
    contact: "Wenche Lund Johanessen",
    poNumber: "65234124",
    addressLine1: "GC Rieber Salt avd. Ålesund",
    addressLine2: "Flatholmen",
    addressPostalCode: "6002",
    addressCity: "Ålesund"
  }
}

function fromFakeSubrow(row) {
  return {
    extProductNo: row[0],
    productNo: row[1],
    description: row[2],
    number: row[3],
    unit: row[4],
    price: row[5],
    tax: row[6],
    amount: row[7],
    currency: row[8],
    canEdit: row[9]
  }
}

function normalizeOrderNumber(orderNumber) {
  if (!orderNumber) {
    return ""
  }

  let start = orderNumber.indexOf("-", 9)
  if (start === -1) {
    return orderNumber
  }

  return orderNumber.substring(0, start)
}