import { Injectable, ElementRef } from '@angular/core';
import { getRect } from '../common/tools';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  private triggers: { [id: string]: PopupTrigger } = {};
  private activeTrigger: string = "";
  private currentPopup: any = undefined;
  public currentClickPopup: any = undefined;

  constructor(private translation: TranslationService) {
    window.addEventListener("mousemove", (event: MouseEvent) => this.onMouseMove(event))
    window.addEventListener("click", (event: MouseEvent) => this.onClick(event), true)
  }

  onClick(event: MouseEvent): void {
    if (this.currentClickPopup) {
      if (isWithin(this.currentClickPopup.element, event.target)) {
        return
      }

      if (isWithin(this.currentClickPopup.trigger, event.target)) {
        this.destroyClickPopup()
        event.stopPropagation()
        return
      }
    }

    this.destroyClickPopup()
  }

  onMouseMove(event: MouseEvent): void {
    if (isWithin(this.currentPopup, event.target)) {
      return
    }

    for (let id in this.triggers) {
      let trigger = this.triggers[id]
      if (trigger.element && isWithin(trigger.element.nativeElement, event.target)) {
        if (this.activeTrigger != id) {
          this.makeIdle(this.activeTrigger)
          this.makeHover(id)
          this.activeTrigger = id
        }

        return
      }
    }

    this.makeIdle(this.activeTrigger)
    this.activeTrigger = undefined
  }

  makeIdle(id: string) {
    if (id) {
      let trigger = this.triggers[id]
      trigger.element.nativeElement.className = trigger.idle
    }
    this.destroyPopup()
  }

  makeHover(id: string) {
    let trigger = this.triggers[id]
    trigger.element.nativeElement.className = trigger.hover
    if (trigger.submenu) {
      let popup = this.createPopup()
      let action = data => {
        this.destroyPopup()
        if (trigger.action) {
          trigger.action(data)
        }
      }
      this.createSubMenu(trigger.element.nativeElement, popup, trigger.submenu, action, trigger.hasIcon)
    }
    if (trigger.topAction) {
      trigger.element.nativeElement.addEventListener('click', trigger.topAction)
    }
  }

  createSubMenu(element, popupContainer, items, action, hasIcon) {
    let padding = 20
    popupContainer.style.padding = padding + "px"
    let popup = make(
      popupContainer,
      "div",
      "popup"
    )

    for (let item of items) {
      if (hasIcon) {
        this.createTopMenuIconItem(popup, item, action)
      } else {
        this.createTopMenuSubItem(popup, item, action)
      }
    }

    let rect = getRect(element)
    let popupRect = getRect(popupContainer)
    var left
    if (rect.left + popupRect.width > window.innerWidth) {
      left = rect.right - popupRect.width + padding
    } else {
      left = rect.left - padding
    }
    placePopupAt(
      popupContainer,
      left,
      rect.bottom
    )
  }

  createClickMenuItem(parent, item) {
    let menuItem = addTag(
      parent,
      "div",
      this.translation.translate(item.text),
      "menu__item"
    )

    if (item.action) {
      menuItem.addEventListener("click", () => {
        this.destroyClickPopup()
        item.action(item.data)
      })
    }
  }

  createTopMenuSubItem(parent, item, action) {
    let menuItem = addTag(
      parent,
      "div",
      this.translation.translate(item.text),
      "menu__item uppercase"
    )

    if (action) {
      menuItem.addEventListener("click", function () {
        action(item.data)
      })
    }
  }

  createTopMenuIconItem(parent, item, action) {
    let menuItem = make(
      parent,
      "div",      
      "menu__item uppercase"
    )

    var icon
    if (item.icon) {
      icon = make(menuItem, "i", "material-icons")
      setText(icon, item.icon)
      icon.style.verticalAlign = "middle"
    } else {
      icon = make(menuItem, "div", "")
    }

    icon.style.display = "inline-block"
    icon.style.width = "15px"

    let text = addTag(menuItem, "span", this.translation.translate(item.text), "")
    text.style.marginLeft = "10px"

    if (action) {
      menuItem.addEventListener("click", function () {
        action(item.data)
      })
    }
  }  

  showClickPopup(trigger: ElementRef, x: number, y: number, items) {

    let popup = this.createClickPopup(trigger)

    for (let item of items) {
      this.createClickMenuItem(popup, item)
    }

    placePopupAt(popup, x + 10, y + 10)
  }

  destroyPopup() {
    if (this.currentPopup) {
      removeElement(this.currentPopup)
      this.currentPopup = undefined
    }
  }

  destroyClickPopup() {
    if (this.currentClickPopup) {
      removeElement(this.currentClickPopup.element)
      this.currentClickPopup = undefined
    }
  }

  addDefinition(definition: PopupDefinition): void {
    this.triggers[definition.id] = {
      id: definition.id,
      idle: definition.idle,
      hover: definition.hover,
      element: undefined,
      submenu: definition.submenu,
      action: definition.action,
      topAction: definition.topAction,
      hasIcon: definition.hasIcon
    }
  }

  addTrigger(element: ElementRef): void {
    let id: string = element.nativeElement.id
    if (!id) {
      throw new Error("The element must have an id")
    }

    let trigger = this.triggers[id]
    if (!trigger) {
      throw new Error("No trigger definition for id: " + id)
    }

    trigger.element = element
    element.nativeElement.className = trigger.idle
  }

  clear(): void {
    this.destroyPopup()
    this.destroyClickPopup()
    this.triggers = {}
    this.activeTrigger = undefined
    this.currentPopup = undefined
    this.currentClickPopup = undefined
  }

  createPopup() {
    let popup = make(
      document.documentElement,
      "div",
      "popup__container"
    )
    popup.style.left = "0px"
    popup.style.top = "0px"
    this.currentPopup = popup
    return popup
  }

  createClickPopup(trigger) {
    let popup = make(
      document.documentElement,
      "div",
      "popup__container popup"
    )
    popup.style.left = "0px"
    popup.style.top = "0px"
    this.currentClickPopup = {
      element: popup,
      trigger: trigger
    }
    return popup
  }

  setMenuItemIcon(popupId: string, itemOrdinal: number, icon: string) {
    let trigger = this.triggers[popupId]
    trigger.submenu[itemOrdinal].icon = icon
  }
}

export class PopupDefinition {
  id: string = "";
  idle: string = "";
  hover: string = "";
  submenu: any[] = undefined;
  action: any = undefined;
  topAction: any;
  hasIcon: boolean;
}

class PopupTrigger {
  id: string = "";
  idle: string = "";
  hover: string = "";
  submenu: any[] = undefined;
  action: any = undefined;
  topAction: any;
  hasIcon: boolean;
  element: ElementRef;
}

function isWithin(container, element) {
  if (!container) {
    return false
  }

  let current = element
  while (current) {
    if (current === container) {
      return true
    }
    current = current.parentElement
  }
  return false
}

function addTag(parent, tag, text, className) {
  var element;
  element = make(parent, tag, className)
  setText(element, text)
  return element
}

function addText(div, text) {
  var node;
  node = document.createTextNode(text)
  div.appendChild(node)
}

function make(parent, tag, className) {
  var element;
  element = document.createElement(tag)
  element.className = className || ""
  parent.appendChild(element)
  return element
}

function removeElement(element) {
  element.parentNode.removeChild(element)
}

function setText(div, text) {
  div.innerHTML = ""
  addText(div, text)
}

function placePopupAt(popup, leftPage, topPage) {
  let left = leftPage - pageXOffset
  let top = topPage - pageYOffset
  let rect = getRect(popup)
  let width = rect.width
  let height = rect.height
  if (left + width > window.innerWidth) {
    left = window.innerWidth - width
  }
  if (left < 0) {
    left = 0
  }
  if (top + height > window.innerHeight) {
    top = window.innerHeight - height
  }
  if (top < 0) {
    top = 0
  }
  popup.style.left = pageXOffset + left + "px"
  popup.style.top = pageYOffset + top + "px"
}