import { Injectable } from '@angular/core';
import { ServerService } from './server.service';
import { LoginService } from './login.service';
import _ from 'lodash';
import { findByProp } from 'src/app/common/tools';

@Injectable({
  providedIn: 'root'
})
export class UserContextService {

  accounts: any[] = [];
  account: string;

  constructor(public server: ServerService, public loginService: LoginService) { }

  getAccount() {
    if (this.account) {
      return this.account
    }

    let parentAccount = this.loginService.getParentAccount()
    if (parentAccount && findByProp(this.accounts, "id", parentAccount)) {
      return parentAccount
    }

    return this.accounts[0].id
  }

  getAccountObject() {
    let accountId = this.getAccount()
    return findByProp(this.accounts, "id", accountId)
  }

  getAccounts() {
    return this.accounts
  }

  setAccount(account) {
    this.account = account
  }

  async fetchAccounts() {
    let accResponse = await this.server.getAccounts()
    this.accounts = accResponse.data
  }
}
