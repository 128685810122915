import { Component, OnInit } from '@angular/core';
import { ServerService } from 'src/app/services/server.service';
import { CrumbStep } from 'src/app/widgets/crumbs/crumbs.component';
import { CustomSnackBarService } from 'src/app/services/custom-snack-bar.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { TranslationService } from 'src/app/services/translation.service';
import { UserContextService } from 'src/app/services/user-context.service';
import _ from 'lodash';
import { pause, findByProp, download } from 'src/app/common/tools';

@Component({
  selector: 'app-data-sheet',
  templateUrl: './data-sheet.component.html',
  styleUrls: ['./data-sheet.component.css']
})
export class DataSheetComponent implements OnInit {

  buzy: boolean = true;

  _account: string = "";
  products: any[];


  path: CrumbStep[] = [
    { text: "Ordre", target: "" },
    { text: "Datablad", target: "" }
  ];

  constructor(public userContext: UserContextService, private server: ServerService,
    private snackBar: CustomSnackBarService, private navigation: NavigationService,
    private translation: TranslationService) { }

  tr(text: string): string {
    return this.translation.translate(text)
  }


  ngOnInit() {
    let url = "/data-sheet"
    if (this.navigation.checkLogin(url, "Datablad")) {
      this.loadProducts()
    }
  }

  async loadProducts() {
    await this.userContext.fetchAccounts()
    this._account = this.userContext.getAccount()

    await this.loadProductsForAccount()

    this.buzy = false
  }

  get account() {
    return this._account
  }

  set account(value) {
    this._account = value
    this.userContext.setAccount(value)
    this.loadProductsForAccount()
  }

  async loadProductsForAccount() {
    this.products = []
    let productsResponse = await this.server.getAccountProducts(this.account)
    this.products = _.orderBy(productsResponse.data, "name")
  }

  toggleExpanded(product) {
    product.expanded = !product.expanded
    if (product.expanded && !product.documents) {
      this.fetchDocumentsForProduct(product)
    }
  }

  async fetchDocumentsForProduct(product) {
    let articles = await this.server.getArticles(product.productNumber)
    let docs = []
    for (let article of articles.data) {
      if (article.attachments) {
        for (let attachment of article.attachments) {
          docs.push({
            attachmentId: attachment.attachmentId,
            fileName: attachment.fileName
          })
        }
      }
    }
    product.documents = _.orderBy(docs, "fileName")
  }

  getAccountName() {
    let accounts = this.userContext.getAccounts()
    let account = findByProp(accounts, "id", this.account)
    return account.name
  }

  async orderDocuments(product) {

    let name = this.getAccountName()
    let subject = "Bestill produkt datablad for " + name
    let body = "Kunde har bestilt produkt datablad via portalen.\n" +
      "Kunde: " + this.account + " " + name + "\n" +
      "Produkt: " + product.productNumberAX + " " + product.productNumber +
      " " + product.name

    product.state = "ordering"
    let result = await this.server.sendEmail(this.account, "Document", subject, body)
    if (result.error) {
      product.state = ""
      this.snackBar.notify("Det oppsto en feil");
    } else {
      product.state = "ordered"
      this.snackBar.notify("Dokumenter bestilt");
    }
  }


  async downloadFile(doc) {
    let content = await this.server.downloadDocument(doc.attachmentId)
    download(content.data)
  }
}


