import { NativeDateAdapter } from '@angular/material';
import { MatDateFormats } from '@angular/material/core';
import { toDateString } from './tools';

export class AppDateAdapter extends NativeDateAdapter {

    getFirstDayOfWeek(): number {
        // We can't tell using native JS Date what the first day of the week is, we default to Sunday.
        return 1;
    }

    format(date: Date, displayFormat: Object): string {
        if (displayFormat === 'long') {
            return toDateString(date)
        }
        return date.toDateString();
    }
}

export const APP_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    },
    display: {
        dateInput: 'long',
        monthYearLabel: { year: 'numeric', month: 'numeric' },
        dateA11yLabel: {
            year: 'numeric', month: 'long', day: 'numeric'
        },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
};