import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { sendPost, getUnixTime, ObservableResult, sendPostRaw } from "../common/tools"
import { ServerService } from './server.service';
import { TranslationService } from './translation.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  url: string;

  constructor(private http: HttpClient, private server: ServerService, private translation: TranslationService) {
    this.url = environment.api + "/api/Auth"
  }

  async login(email: string, password: string): Promise<LoginResult> {
    let payload = {
      userPrincipalName: email,
      password: password
    }

    let response = await sendPostRaw(this.http, this.url, payload, undefined)

    if (response.error) {
      let message = getErrorMessage(response)
      return {
        success: false,
        message: message
      }
    }

    // saveToken(response.data.Access_token, parseInt(response.data.Expires_in))
    saveToken(response.data.access_token, parseInt(response.data.expires_in))

    let contact = await this.server.fetchContactInformation()
    let language = this.translation.chooseLanguage(contact.preferredLanguage)
    this.translation.setLanguage(language)

    if (contact.parentAccount) {
      localStorage.setItem("parent-account", contact.parentAccount)
    }

    return {
      success: true,
      message: ""
    }
  }

  getParentAccount() {
    return localStorage.getItem("parent-account")
  }

  isLoggedIn(): boolean {
    let token = localStorage.getItem("access_token")
    if (token) {
      let now = getUnixTime()
      let expiry = parseInt(
        localStorage.getItem("token-expiry")
      )
      if ((isNaN(expiry)) || (now > expiry)) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  logout() {
    localStorage.removeItem("access_token")
    localStorage.removeItem("token-expiry")
    localStorage.removeItem("parent-account")
  }


}

export class LoginResult {
  success: boolean = false;
  message: string = "";
}

function saveToken(token: string, expiresIn: number) {
  let now = getUnixTime()
  let expiry = now + expiresIn - 60
  localStorage.setItem("access_token", token)
  localStorage.setItem(
    "token-expiry",
    expiry.toString()
  )
}

function getErrorMessage(response: ObservableResult): string {
  let message = "Ukjent feil"
  if (response.error.error && response.error.error.message) {
    message = response.error.error.message
  } else if (response.error.error && response.error.error.status === 401) {
    message = "Feil passord eller brukernavn"
  } else if (response.error.message) {
    message = response.error.message
  }
  return message
}

