import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-incoming-orders',
  templateUrl: './incoming-orders.component.html',
  styleUrls: ['./incoming-orders.component.css']
})
export class IncomingOrdersComponent implements OnInit {
  showForm: boolean = true;
  blockValueChange: boolean = false;
  fromEmail: string;
  customer: string = "";
  orderDate: string = "";
  orderedBy: string = "";
  customerOrderId: string = "";
  incoterm: string = "";
  lines: any[] = [];


  presets: any = {};


  clients: any[] = getFakeCustomers()
  products: any[] = getProducts()

  currentOrder: number = 0;
  orders: any[] = getFakeOrders()

  form = new FormGroup({
    input: new FormControl(""),
    customer: new FormControl(""),
    orderElement: new FormControl(""),
    itemElement: new FormControl(""),
    orderDate: new FormControl(""),
    orderedBy: new FormControl(""),
    customerOrderId: new FormControl(""),
    incoterm: new FormControl(""),
    customerProductNumber: new FormControl(""),
    quantity: new FormControl(""),
    price: new FormControl(""),
    currency: new FormControl(""),
    orderDateType: new FormControl(""),
    orderedByType: new FormControl(""),
    customerOrderIdType: new FormControl(""),
    incotermType: new FormControl(""),
    customerProductNumberType: new FormControl(""),
    quantityType: new FormControl(""),
    priceType: new FormControl(""),
    currencyType: new FormControl("")
  });

  constructor() { }

  ngOnInit() {
    this.form.valueChanges.subscribe(value => {
      this.onPresetChange(value)
    })

    this.setOrder()
  }

  changeProductMapping($event, line) {
    if (!line.customerProductNumber) {
      return
    }
    let preset = this.getOrCreatePreset()
    let value = $event.target.value
    preset.products[line.customerProductNumber] = value
    this.parseInput()
  }

  getProductName(item) {
    let product = this.getProduct(item)
    if (!product) {
      return ""
    }

    return product.name
  }

  getProductDesc(item) {
    let product = this.getProduct(item)
    if (!product) {
      return ""
    }

    return product.description
  }

  getProductUnit(item) {
    let product = this.getProduct(item)
    if (!product) {
      return ""
    }

    return product.unit
  }

  getProduct(item) {
    if (!item) {
      return undefined
    }

    if (!item.axId) {
      return undefined
    }

    for (let product of this.products) {
      if (item.axId === product.axId) {
        return product
      }
    }

    return undefined
  }

  onPresetChange(value) {
    if (!this.fromEmail) {
      return
    }

    if (this.blockValueChange) {
      return
    }

    this.savePreset(value)

    this.parseInput()
  }

  clearOutput() {
    this.orderDate = ""
    this.orderedBy = ""
    this.customerOrderId = ""
    this.incoterm = ""
    this.lines = []
  }

  parseInput() {
    let preset = this.getOrCreatePreset()
    let xmlString = this.form.controls.input.value
    let xml = parseXml(xmlString)

    this.clearOutput()

    let orderNode = getOrderNode(preset, xml)
    if (!orderNode) {
      return
    }

    this.orderDate = getXmlValue(preset, orderNode, "orderDate")
    this.orderedBy = getXmlValue(preset, orderNode, "orderedBy")
    this.customerOrderId = getXmlValue(preset, orderNode, "customerOrderId")
    this.incoterm = getXmlValue(preset, orderNode, "incoterm")

    let lines = getLines(preset, orderNode)

    for (let lineXml of lines) {
      let line = {
        customerProductNumber: getXmlValue(preset, lineXml, "customerProductNumber"),
        quantity: getXmlValue(preset, lineXml, "quantity"),
        price: getXmlValue(preset, lineXml, "price"),
        currency: getXmlValue(preset, lineXml, "currency"),
        axId: ""
      }

      line.axId = this.getMappedProduct(preset, line)
      this.lines.push(line)
    }
  }

  getMappedProduct(preset, line) {
    if (!line.customerProductNumber) {
      return ""
    }
    let product = preset.products[line.customerProductNumber]
    if (!product) {
      return ""
    }

    return product
  }

  save() {
    if (this.currentOrder < this.orders.length - 1) {
      this.currentOrder++
      this.setOrder()
    } else {
      this.showForm = false
    }
  }
  setOrder() {
    this.blockValueChange = true
    let record = this.orders[this.currentOrder]
    this.form.controls.input.setValue(record.xml)
    this.fromEmail = record.email
    this.readPreset()
    this.parseInput()
    this.blockValueChange = false
  }

  savePreset(value) {
    console.log("save presets")
    let preset = this.getOrCreatePreset()
    this.blockValueChange = true
    saveFieldPreset(preset, value, "customer")
    saveFieldPreset(preset, value, "orderElement")
    saveFieldPreset(preset, value, "itemElement")
    saveFieldPreset(preset, value, "orderDate")
    saveFieldPreset(preset, value, "orderedBy")
    saveFieldPreset(preset, value, "customerOrderId")
    saveFieldPreset(preset, value, "incoterm")
    saveFieldPreset(preset, value, "customerProductNumber")
    saveFieldPreset(preset, value, "quantity")
    saveFieldPreset(preset, value, "price")
    saveFieldPreset(preset, value, "currency")
    this.blockValueChange = false
  }


  readPreset() {
    let preset = this.getOrCreatePreset()

    readFieldPreset(preset, this.form, "customer")
    readFieldPreset(preset, this.form, "orderElement")
    readFieldPreset(preset, this.form, "itemElement")
    readFieldPreset(preset, this.form, "orderDate")
    readFieldPreset(preset, this.form, "orderedBy")
    readFieldPreset(preset, this.form, "customerOrderId")
    readFieldPreset(preset, this.form, "incoterm")
    readFieldPreset(preset, this.form, "customerProductNumber")
    readFieldPreset(preset, this.form, "quantity")
    readFieldPreset(preset, this.form, "price")
    readFieldPreset(preset, this.form, "currency")
  }

  getOrCreatePreset() {
    let preset = this.presets[this.fromEmail]
    if (!preset) {
      preset = { email: this.fromEmail, fields: {}, products: {} }
      this.presets[this.fromEmail] = preset
    }
    return preset
  }
}

function getOrderNode(preset, xml) {
  let orderElements = getElements(preset, xml, "orderElement")
  if (orderElements.length == 0) {
    return undefined
  }

  return orderElements[0]
}

function getLines(preset, xml) {
  return getElements(preset, xml, "itemElement")
}

function getElements(preset, xml, name) {
  let xmlName = preset.fields[name]
  if (!xmlName) {
    return []
  }

  return xml.getElementsByTagName(xmlName)
}

function parseXml(xmlString) {
  if (window["DOMParser"]) {
    var parser = new DOMParser();
    return parser.parseFromString(xmlString, "text/xml");
  }

  return undefined
}

function getXmlValue(preset, xml, name): string {
  let xmlName = preset.fields[name]
  if (!xmlName) {
    return ""
  }

  let typeName = name + "Type"
  let type = preset.fields[typeName] || "attribute"

  if (type === "attribute") {

    let attribute = xml.attributes[xmlName]
    if (attribute) {
      return attribute.value
    }
  } else {
    let elements = xml.getElementsByTagName(xmlName)
    if (elements.length == 0) {
      return ""
    }

    return elements[0].textContent
  }


  return ""
}

function saveFieldPreset(preset, value, name) {
  let fieldValue = value[name]
  if (fieldValue === undefined) {
    fieldValue = ""
  }

  preset.fields[name] = fieldValue.trim()

  let typeName = name + "Type"
  let typeValue = value[typeName]
  if (typeValue === undefined) {
    typeValue = ""
  }
  preset.fields[typeName] = typeValue.trim()
}

function readFieldPreset(preset, form, name) {
  let control = form.controls[name]
  let value = preset.fields[name]
  control.setValue(value)
  let typeName = name + "Type"
  let typeControl = form.controls[typeName]
  if (typeControl) {
    let typeValue = preset.fields[typeName]
    typeControl.setValue(typeValue)
  }
}

function getFakeCustomers() {
  return [{ id: "cust-1", name: "Giant plants society AS" }, { id: "cust-2", name: "Monsters Inc." }]
}


function getFakeOrders() {
  let order1 = `<?xml version="1.0" encoding="UTF-8"?>
  <Report xmlns="PurchPurchaseOrder.Report" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="PurchPurchaseOrder.Report http://ax-uat-rep01/ReportServer?%2FDynamicsAX%2FStaticReports%2Fnb-NO%2FPurchPurchaseOrder.Report&amp;rs%3AFormat=XML&amp;rc%3ASchema=True" Name="PurchPurchaseOrder.Report">
     <table5 textbox146="Enhet" textbox148="Enhetspris" Textbox55="Beløp" NotesTrans="" Textbox35="" textbox209="Vennligst påse at faktura og andre dokumenter relatert til dette innkjøpet merkes med vårt bestillingsnummer.">
        <Detail_Collection>
           <Detail textbox84="1" textbox85="3128" textbox86="NSK Nitritsalt, 25 kg plastsæk, EUR-palle" textbox87="2019-09-26T00:00:00" textbox88="25" textbox89="PL" textbox99="1150" textbox91="0" Textbox132="NOK" textbox103="28750" />
           <Detail textbox84="2" textbox85="10300" textbox86="Europall" textbox87="2019-09-26T00:00:00" textbox88="25" textbox89="Piec" textbox99="90" textbox91="0" Textbox132="NOK" textbox103="2250" />
        </Detail_Collection>
     </table5>
  </Report>`

  let order2 = `<?xml version="1.0" encoding="utf-8"?><Report xsi:schemaLocation="PurchPurchaseOrder.Report http://ax-prod-rep01/ReportServer?%2FDynamicsAX%2FStaticReports%2Fen-US%2FPurchPurchaseOrder.Report&amp;rs%3AFormat=XML&amp;rc%3ASchema=True" Name="PurchPurchaseOrder.Report" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="PurchPurchaseOrder.Report">
  <table5 textbox146="Unit" textbox148="Unit price" Textbox55="Amount" NotesTrans="" Textbox35="" textbox209="Please ensure that invoices and other documents related to this purchase are marked with our purchase order number."><Detail_Collection>
  <Detail textbox84="1" textbox85="168524" textbox86="Bicar Z 1200 kg auf HT-Palle" textbox87="2019-10-10T00:00:00" textbox88="20" textbox89="storsekk" textbox99="362.4" textbox91="0" Textbox132="EUR" textbox103="7248" />
  <Detail textbox84="2" textbox85="168524" textbox86="Bicar Z 1200 kg auf HT-Palle" textbox87="2019-10-11T00:00:00" textbox88="20" textbox89="storsekk" textbox99="362.4" textbox91="0" Textbox132="EUR" textbox103="7248" />
  <Detail textbox84="3" textbox85="168524" textbox86="Bicar Z 1200 kg auf HT-Palle" textbox87="2019-10-14T00:00:00" textbox88="20" textbox89="storsekk" textbox99="362.4" textbox91="0" Textbox132="EUR" textbox103="7248" />
  <Detail textbox84="4" textbox85="168524" textbox86="Bicar Z 1200 kg auf HT-Palle" textbox87="2019-10-14T00:00:00" textbox88="20" textbox89="storsekk" textbox99="362.4" textbox91="0" Textbox132="EUR" textbox103="7248" /></Detail_Collection></table5></Report>`

  let order3 = `<?xml version="1.0" encoding="UTF-8"?>
  <purchaseOrder>
    <orderId>ABC</orderId>
    <orderedBy>Test Testessen</orderedBy>
    <orderDate>2019-09-26</orderDate>
    <incoterm>2019-09-26</incoterm>
    <orderLines>
      <item>
        <productName>Cool salt</productName>
        <quantity>10</quantity>
        <price>800</price>
      </item>
      <item>
        <productName>Black salt</productName>
        <quantity>20</quantity>
        <price>500</price>
      </item>
      <item>
        <productName>Grey salt</productName>
        <quantity>550</quantity>
        <price>30</price>
      </item>
    </orderLines>
  </purchaseOrder>`

  let order4 = `<?xml version="1.0" encoding="UTF-8"?>
  <purchaseOrder>
    <orderId>CDE</orderId>
    <orderedBy>Mic Isin</orderedBy>
    <orderDate>2019-09-27</orderDate>
    <incoterm>HOHO</incoterm>
    <orderLines>
      <item>
        <productName>Cool salt</productName>
        <quantity>20</quantity>
        <price>800</price>
      </item>
      <item>
        <productName>Black salt</productName>
        <quantity>40</quantity>
        <price>500</price>
      </item>
      <item>
        <productName>Grey salt</productName>
        <quantity>750</quantity>
        <price>30</price>
      </item>
    </orderLines>
  </purchaseOrder>`

  return [
    { date: "2019-09-20", email: "one@plants.com", xml: order1 },
    { date: "2019-09-21", email: "one@plants.com", xml: order2 },
    { date: "2019-09-20", email: "one@plants.com", xml: order1 },
    { date: "2019-09-22", email: "two@monsters.com", xml: order3 },
    { date: "2019-09-22", email: "two@monsters.com", xml: order4 },
    { date: "2019-09-20", email: "one@plants.com", xml: order1 },
    { date: "2019-09-22", email: "two@monsters.com", xml: order3 },
    { date: "2019-09-22", email: "two@monsters.com", xml: order4 }
  ]
}

function getProducts() {
  return [
    { axId: "11111", name: "HavsaltMarsel2-4mm25", description: "Gabion nipper splice the main brace jib pressgang", unit: "Stk" },
    { axId: "22222", name: "HavsaltMarsel0,2-0,8", description: "Cable rigging tender run a rig long clothes hogshead", unit: "Sekk" },
    { axId: "33333", name: "Steinsaltfint25kg", description: "Admiral of the Black coffer furl crimp", unit: "Stk" },
    { axId: "44444", name: "Havsalt1000kgStorsek", description: "Jack Ketch sheet lugger fathom wench", unit: "Storsekk" },
    { axId: "55555", name: "Havsalt25kgsekk", description: "Spanker squiffy jib piracy clap of thunder Jack Tar", unit: "Sekk" }
  ]
}