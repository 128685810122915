import { Component, OnInit, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';
import { LoginService } from 'src/app/services/login.service';
import { markLanguageInMenu } from 'src/app/common/tools';
import { PopupService } from 'src/app/services/popup.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.css']
})
export class MobileMenuComponent implements OnInit {
  showMenu: boolean = false;
  @ViewChildren("menuItem") menuItems !: QueryList<ElementRef>;

  constructor(public translation: TranslationService, private loginService: LoginService,
    private popupService: PopupService, private navigation: NavigationService) { }

  tr(text: string): string {
    return this.translation.translate(text)
  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  logoff() {
    this.loginService.logout()
    window.location.href = "/login"
  }

  ngAfterViewInit() {
    for (let item of this.menuItems.toArray()) {
      item.nativeElement.addEventListener("click", () => this.hideMenu())
    }
  }

  hideMenu() {
    this.showMenu = false
  }

  onLanguageChange(event) {
    this.translation.switchLanguage(event.value)
    markLanguageInMenu(this.popupService, event.value)
    this.navigation.updateTitle()
  }
}
