import { Component, OnInit, ViewChild } from '@angular/core';
import { CrumbStep } from 'src/app/widgets/crumbs/crumbs.component';
import { TranslationService } from 'src/app/services/translation.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { ServerService, DeliveryOptions } from 'src/app/services/server.service';
import _ from 'lodash';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/common/format-datepicker';
import { toDateString, replaceAll, buildContactName, pause } from 'src/app/common/tools';
import { UserContextService } from 'src/app/services/user-context.service';
import { OrderLinesComponent } from 'src/app/widgets/order-lines/order-lines.component';
import { CustomSnackBarService } from 'src/app/services/custom-snack-bar.service';

@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class CreateOrderComponent implements OnInit {

  state: string = "normal";
  customerConfirm: boolean = true;

  @ViewChild(OrderLinesComponent, { static: false }) orderLinesElement: OrderLinesComponent;

  buzy: boolean = true;
  error: string = "";
  visibleColumn: number = 0;

  order: any = undefined;
  orderId: string;
  orderedBy: string;

  deliveryOptions: DeliveryOptions;
  accounts: any[];

  _account: string = "";
  currency: string = "";

  addresses: any[];
  address: any = {};

  products: any[];
  lastProducts: any[];
  columns: any[] = [
    { id: "extProductNo", name: "Ditt produktnummer" },
    { id: "productNo", name: "Vårt produktnummer" },
    { id: "description", name: "Beskrivelse" }
  ]

  path: CrumbStep[] = [
    { text: "Ordre", target: "" },
    { text: "Ny ordre", target: "" }
  ];

  title: string;

  constructor(private snackBar: CustomSnackBarService, private translation: TranslationService, private navigation: NavigationService, public route: ActivatedRoute,
    public server: ServerService, public userContext: UserContextService) { }

  tr(text: string): string {
    return this.translation.translate(text)
  }

  ngOnInit() {
    this.orderId = this.route.snapshot.params.orderId
    this.state = this.route.snapshot.data.mode || "normal"
    var url
    if (this.state === "edit") {
      url = "/orders/" + this.orderId + "/edit"
      this.setEditOrderTitle()
      if (this.navigation.checkLogin(url, this.title)) {
        this.loadOrder(false)
      }
    } else if (this.state === "normal") {
      url = "/create-order"
      this.title = "Ny ordre"
      this.path = [
        { text: "Ordre", target: "" },
        { text: "Ny ordre", target: "" }
      ]
      if (this.navigation.checkLogin(url, this.title)) {
        this.createOrder()
      }
    } else if (this.state === "copy") {
      this.state = "normal"
      url = "/copy/" + this.orderId
      this.title = "Ny ordre"
      this.path = [
        { text: "Ordre", target: "" },
        { text: "Ny ordre", target: "" }
      ]
      if (this.navigation.checkLogin(url, this.title)) {
        this.loadOrder(true)
      }
    } else if (this.state === "view") {
      url = "/history/" + this.orderId
      this.title = "Ordredetaljer"
      this.path = [
        { text: "Ordre", target: "" },
        { text: "Ordrehistorikk", target: "/history" },
        { text: "Ordredetaljer", target: "" }
      ];

      if (this.navigation.checkLogin(url, this.title)) {
        this.loadOrder(false)
      }
    } else if (this.state === "confirmation") {
      url = "/orders/" + this.orderId
      this.setConfirmationTitle()
      this.customerConfirm = true
      if (this.navigation.checkLogin(url, this.title)) {
        this.loadOrder(false)
      }
    } else if (this.state === "customer") {
      url = "/confirm/" + this.orderId
      this.setConfirmationTitle()
      if (this.navigation.checkLogin(url, this.title)) {
        this.loadOrder(false)
      }
    } else {
      console.error("Bad state", this.state)
    }
  }

  setConfirmationTitle() {
    this.title = "Ordredetaljer"
    this.path = [
      { text: "Ordre", target: "" },
      { text: "Åpne ordrer", target: "/orders" },
      { text: "Ordredetaljer", target: "" }
    ]
  }

  setEditOrderTitle() {
    this.title = "Endre ordre"
    this.path = [
      { text: "Ordre", target: "" },
      { text: "Åpne ordrer", target: "/orders" },
      { text: "Endre ordre", target: "" }
    ]
  }

  async prepare() {
    let doResponse = await this.server.getDeliveryOptions()
    this.deliveryOptions = doResponse.data

    this.deliveryOptions.deliveryTerms.sort()
    await this.userContext.fetchAccounts()


    this.accounts = this.userContext.getAccounts()
    this._account = this.userContext.getAccount()
  }


  async createOrder() {
    await this.prepare()

    await this.resetOrder()

    this.buzy = false
  }

  async resetOrder() {
    this.orderedBy = ""

    this.order = {
      deliveryAddress: undefined,
      deliveryDate: "",
      confirmedDeliveryDate: "",
      customerRequisition: "",
      customerReference: "",
      deliveryTerms: "",
      currency: "",
      description: "",
      site: "",
      lines: []
    }

    await this.getDefaultsForAccount()
  }

  async loadOrder(clearRef) {
    this.orderedBy = ""
    await this.prepare()

    let orderResult = await this.server.getOrder(this.orderId)
    this.order = orderResult.data
    this.order.deliveryTerms = this.order.deliveryTermsText
    var lines
    if (this.order.lines) {
      lines = this.order.lines.map(convertOrderLine)
    } else {
      lines = []
    }

    const documentStatus = {
      778380000: "Registered",
      778380001: "Confirmation",
      778380002: "Invoice"
    }

    this.order.documentStatusAx = documentStatus[this.order.documentStatusAx]

    this._account = this.order.customer
    this.userContext.setAccount(this._account)
    await this.getDefaultsForAccount()
    if (clearRef) {
      this.order.customerRequisition = ""
      this.order.customerReference = ""
      this.order.deliveryDate = ""
      this.order.confirmedDeliveryDate = ""
      this.order.contact = ""
      this.order.orderNumber = ""
      this.order.salesStatusAx = ""
      this.order.documentStatusAx = ""
    }

    if (this.order.deliveryAddress) {
      this.address = this.order.deliveryAddress
    }
    this.order.lines = lines

    this.fetchOrderedBy()

    this.buzy = false
  }

  get account() {
    return this._account
  }

  set account(value) {
    this._account = value
    this.userContext.setAccount(value)

    this.order.deliveryTerms = undefined
    this.getDefaultsForAccount()
  }

  async getDefaultsForAccount() {

    let addressesResponse = await this.server.getAddresses(this.account)
    this.addresses = addressesResponse.data

    if (this.addresses.length === 0) {
      this.address = {}
    } else {
      this.address = this.addresses[0]
      this.address.chosen = true
    }

    let accountObj = this.getAccount()
    this.currency = accountObj.currency.isoCode

    if (this.order.deliveryTermsText != "") {
      this.order.deliveryTermsText = accountObj.deliveryTermsText || this.deliveryOptions.deliveryTerms[0]
    }

    if (!this.order.deliveryTerms) {
      this.order.deliveryTerms = accountObj.deliveryTermsText || this.deliveryOptions.deliveryTerms[0]
    }

    this.order.site = this.getSite()
    this.order.lines = []

    let productsResponse = await this.server.getProducts(this.getLegalEntity())
    this.products = productsResponse.data

    let lastProductsResponse = await this.server.getAccountProducts(this.account)
    this.lastProducts = lastProductsResponse.data
  }

  async fetchOrderedBy() {
    if (this.order.contact) {
      try {
        let contactResult = await this.server.getContact(this.order.contact)
        if (contactResult.data) {
          this.orderedBy = buildContactName(contactResult.data)
        }
      } catch (e) {

      }
    }
  }

  getSite() {
    if (this.order.deliverySite) {
      return this.order.deliverySite
    } else {
      return this.getAccount().deliverySite
    }
  }

  getLegalEntity() {
    let entity = this.getAccount().legalEntity || "Norway"
    return entity
  }

  getAccounts() {
    return _.orderBy(this.accounts, ["name"])
  }

  getCurrency() {
    return this.getAccount().currency.isoCode
  }

  getAccount() {
    return _.find(this.accounts, { id: this.account })
  }

  getSites() {
    let entity = this.getLegalEntity()
    let sites = this.deliveryOptions.deliverySites[entity] || []
    let sitesCopy = sites.slice()
    sitesCopy.sort()
    return sitesCopy
  }

  isSiteVisible() {
    if (!this.order) {
      return false
    }

    let terms = this.order.deliveryTerms

    if (terms === "FCA" || terms === "EXW" || terms === "FAS" || terms === "FOB") {
      return true
    }
    // if (terms === 778380010 || terms === 778380008 || terms === 778380009 || terms === 778380011) {
    //   return true
    // }

    return false
  }

  onAddressChanged(address) {
    this.address = address
  }

  async sendOrder() {
    this.error = ""
    if (this.order.lines.length === 0) {
      this.error = this.tr("Legg til én eller flere ordrelinjer")
      return
    }

    if (!this.address || !this.address.line1) {
      this.error = this.tr("Velg en leveringsadresse")
      return
    }

    if (!this.order.deliveryDate) {
      this.error = this.tr("Angi") + " " + this.getDateLabel()
      return
    }

    this.state = "confirm"
    this.title = "Bekreft ordre"
  }

  backToEdit() {
    this.state = "normal"
    this.title = "Ny ordre"
    this.orderLinesElement.showQuantities()
  }

  async confirmOrder() {
    let order = this.extractOrderObject()

    this.state = "sending"

    let result = await this.server.sendOrder(order)
    if (result.data) {
      this.notify("Din ordre er mottatt")
      this.goToOpenOrders()
    } else {
      this.notify("Det oppsto en feil")
      this.state = "normal"
      this.orderLinesElement.showQuantities()
    }
  }

  extractOrderObject() {
    let outputOrder = {
      customer: this.account,
      customerReference: this.order.customerReference,
      customerRequisition: this.order.customerRequisition,
      deliveryTerms: this.order.deliveryTerms,
      deliveryTermsText: this.order.deliveryTermsText,
      deliverySite: this.order.site,
      lines: this.order.lines.map(extractOrderLine),
      deliveryDate: this.getDeliveryDate(),
      deliveryAddress: {
        name: this.address.name,
        addressId: this.address.addressId,
        line1: this.address.line1,
        line2: this.address.line2,
        line3: this.address.line3,
        postalCode: this.address.postalCode,
        city: this.address.city,
      },
      description: this.order.description
    }

    return outputOrder
  }

  getDeliveryDate() {
    return this.order.deliveryDate || ""
  }

  getSendOrderText() {
    if (this.state === "normal") {
      return this.tr("Send ordre")
    } else {
      return this.tr("Sender ordre...")
    }
  }

  notify(message: string) {
    this.snackBar.notify(message)
  }

  getDateLabel() {
    if (this.isSiteVisible()) {
      return this.tr('Ønsket hentedato')
    } else {
      return this.tr('Ønsket mottakelsesdato')
    }
  }

  getConfirmedDateLabel() {
    if (this.isSiteVisible()) {
      return this.tr('Bekreftet hentedato')
    } else {
      return this.tr('Bekreftet mottakelsesdato')
    }
  }

  copyOrder() {
    this.navigation.goToUrl("/copy/" + this.orderId, undefined)
  }


  goToOpenOrders() {
    this.navigation.goToUrl("/orders", undefined)
  }

  goToHistory() {
    this.navigation.goToUrl("/history", undefined)
  }


  goToEdit() {
    this.state = "edit"
    this.orderLinesElement.showQuantities()
    this.setEditOrderTitle()
  }

  goToConfirmation() {
    this.state = "confirmation"
    this.setConfirmationTitle()
  }

  newOrder() {
    this.state = "normal"
  }

  async confirmSuggestedOrder() {
    this.state = "customer-sending"
    await this.server.confirmSuggestedOrder(this.orderId)
    this.notify("Beslutning sendt")
    this.goToOpenOrders()
  }

  async rejectSuggestedOrder() {
    this.state = "customer-sending"
    await this.server.rejectSuggestedOrder(this.orderId)
    this.notify("Beslutning sendt")
    this.goToOpenOrders()
  }

  isEditable() {
    return this.state === "normal" || this.state === "edit"
  }

  getDate() {
    return toDateString(this.order.deliveryDate)
  }

  getConfirmedDate() {
    return toDateString(this.order.confirmedDeliveryDate)
  }

  getDescriptionLines() {
    if (this.order.description) {
      return this.order.description.split("\n")
    } else {
      return [""]
    }
  }

  getTermsDescription() {
    let terms = getTermsFor(this.order.deliveryTerms)
    if (terms) {
      return this.tr(terms)
    } else {
      return ""
    }
  }

  getTermsShortText(terms) {
    return getTermsShortText(terms)
  }

  removeUnderscore(category) {
    return category
  }
}

function extractOrderLine(line) {
  return {
    productId: line.id,
    quantity: line.number
  }
}

function convertOrderLine(line) {
  return {
    id: line.productId,
    number: line.quantity,
    productNumberAX: line.product.productNumberAX,
    name: line.product.name,
    unit: line.product.unitOfMeasurement.name,
    pricePerUnit: line.pricePerUnit,
    extendedAmount: line.extendedAmount
  }
}


function getTermsFor(terms) {
  let allTerms = {
    "DDP": "GCR Salt frakter varen til angitt bestemmelsessted, og bærer kostnadene forbundet med transport, samt toll og avgifter.",
    "DDU": "",
    "CFR": "GCR Salt frakter varen til angitt ankomsthavn, og bærer alle kostnader, eksklusiv forsikring til ankomsthavn.",
    "CIF": "GCR Salt frakter varen til angitt ankomsthavn, og bærer alle kostnader, inklusiv forsikring til ankomsthavn.",
    "CIP": "GCR Salt frakter varen til angitt sted, og bærer alle kostnadene inkludert frakt og forsikring til angitt sted.",
    "CPT": "GCR Salt frakter varen til angitt sted, og bærer alle kostnadene inkludert frakt, men ekskludert forsikring.",
    "DAP": "GCR Salt frakter varen til angitt bestemmelsessted før lossing har funnet sted, og bærer transportkostnadene frem til bestemmelsesstedet.",
    "DAT": "GCR Salt frakter varen til en avtalt bestemt plass ved bestemmelsesstedet og bærer transportkostnadene frem til varene er losset av transportmiddelet.",
    "EXW": "Varen stilles til disposisjon til kunden ved GCR Salt lagerlokasjon. Selger laster selv varen om bord transportmiddelet.",
    "FAS": "GCR Salt frakter varen til angitt lastehavn, og bærer kostnader kun frem til skipssiden i utskipningshavn, kjøper dekker det øvrige kostnadene.",
    "FCA": "Varen stilles til disposisjon til kunden, ferdig opplastet på bil ved GCR Salt lager lokasjon.",
    "FOB": "GCR Salt frakter varen til angitt lastehavn, og bærer kostnader kun frem til varene er lastet om bord i skip."
  }

  return allTerms[terms]
}

function getTermsShortText(terms) {
  let texts = {
    "DDP": "Delivered duty paid",
    "DDU": "Delivered duty unpaid",
    "CFR": "Cost and freight",
    "CIF": "Cost, insurance and freight",
    "CIP": "Carriage and insurance paid to",
    "CPT": "Carriage paid to",
    "DAP": "Delivered at place",
    "DAT": "Delivered at terminal",
    "EXW": "Ex Works",
    "FAS": "Free alongside ship",
    "FCA": "Free carrier",
    "FOB": "Free on board"
  }

  return texts[terms]
}