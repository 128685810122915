import { Component, OnInit, Input } from '@angular/core';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-order-lines-money',
  templateUrl: './order-lines-money.component.html',
  styleUrls: ['./order-lines-money.component.css']
})
export class OrderLinesMoneyComponent implements OnInit {

  @Input()
  order: any;

  @Input()
  currency: string = "";

  rightColumns: any[] = [
    { id: "number", name: "Antall" },
    { id: "unit", name: "Enhet" },
    { id: "productNumberAX", name: "Produktnummer" },
    { id: "pricePerUnit", name: "Pris per enhet" },
    { id: "extendedAmount", name: "Beløp" },
  ]

  visibleColumn: number = 0;




  constructor(private translation: TranslationService) { }

  tr(text: string): string {
    return this.translation.translate(text)
  }

  ngOnInit() {
  }

  onColumnChanged(event) {
    this.visibleColumn = event.value
  }

  getRightValue(line) {
    let column = this.rightColumns[this.visibleColumn]
    let rawValue = line[column.id]
    if (!rawValue) {
      return ""
    }
    var value
    if (column.id === "pricePerUnit" || column.id === "extendedAmount") {
      value = rawValue + " " + this.currency
    } else {
      value = rawValue
    }

    return value
  }
}
