import { Component, OnInit, QueryList, ElementRef, ViewChildren, Input } from '@angular/core';
import { PopupService } from 'src/app/services/popup.service';
import { Router } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { TranslationService } from 'src/app/services/translation.service';
import { LoginService } from 'src/app/services/login.service';
import { markLanguageInMenu } from 'src/app/common/tools';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.css']
})
export class ShellComponent implements OnInit {

  @Input() selectedMenu: string;
  @Input() buzy: boolean = false;

  menuItems: TopMenuItem[];

  @ViewChildren("topMenuItem")
  menuItemElements: QueryList<ElementRef>;

  constructor(private popupService: PopupService, private router: Router,
    private translation: TranslationService, private loginService: LoginService, private navigation: NavigationService) { }

  tr(text: string): string {
    return this.translation.translate(text)
  }

  ngOnInit() {
    this.menuItems = [
      { id: "orders", text: "Ordre" },
      { id: "profile", text: "Min profil" },
      { id: "contact", text: "Kontakt" },
      { id: "terms", text: "Vilkår" },
      { id: "logoff", text: "Logg av" }
    ];

    this.addTopMenuDefinition("orders", [
      { text: "Ny ordre", data: "/create-order" },
      { text: "Åpne ordrer", data: "/orders" },
      { text: "Ordrehistorikk", data: "/history" },
      { text: "Datablad", data: "/data-sheet" }
    ], undefined)
    this.addTopMenuDefinition("profile", [
      { text: "Innstillinger", data: "/profile" }
    ], undefined)
    this.addTopMenuDefinition("contact", [
      { text: "Kundeservice", data: "/customer-service" },
      { text: "Om oss", data: "/about" }
    ], undefined)
    this.addTopMenuDefinition("terms", [
      { text: "Avtale/Kontrakt", data: "/contract" },
      { text: "Kjøpsbetingelser", data: "/terms" }
    ], undefined)

    let logoff = () => this.logoff()
    this.addTopMenuDefinition("logoff", [], logoff)

    this.addLangugesItemDefinition()

    markLanguageInMenu(this.popupService, this.translation.language)
  }

  logoff() {
    this.loginService.logout()
    window.location.href = "/login"
  }

  addTopMenuDefinition(id: string, submenu: any[], topAction: any) {
    let action = target => {
      this.router.navigate([target])
    }

    var idle = "top-menu__item"
    var hover = "top-menu__item top-menu__item-selected"
    if (this.selectedMenu && this.selectedMenu === id) {
      idle = hover
    }

    this.popupService.addDefinition({
      id: id,
      idle: idle,
      hover: hover,
      submenu: submenu,
      action: action,
      topAction: topAction,
      hasIcon: false
    })
  }

  addLangugesItemDefinition() {
    let action = language => {
      this.translation.switchLanguage(language)
      markLanguageInMenu(this.popupService, language)
      this.navigation.updateTitle()
    }

    var idle = "top-menu__item"
    var hover = "top-menu__item top-menu__item-selected"

    this.popupService.addDefinition({
      id: "languages",
      idle: idle,
      hover: hover,
      submenu: [
        { text: "English", data: "en" },
        { text: "Dansk", data: "da" },
        { text: "Norsk", data: "nb" }
      ],
      action: action,
      topAction: undefined,
      hasIcon: true
    })
  }

  ngAfterViewInit() {
    for (let element of this.menuItemElements.toArray()) {
      this.popupService.addTrigger(element)
    }
  }

  ngOnDestroy() {
    this.popupService.clear()
  }
}

export class TopMenuItem {
  id: string = "";
  text: string = "";
}
